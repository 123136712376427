<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Clients">

                <b-button variant="black2" @click="addClientModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="clientsFormatted" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="openEdit(clients[props.row.index])" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button variant="danger" @click="removeClient(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </basic-table>

            </b-card>
        </b-overlay>

        <b-modal title="Add client" v-model="addClientModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Address</label>
                    <b-form-input v-model="addObject.address"/>
                </b-form-group>

                <b-form-group>
                    <label>Country</label>
                    <b-form-input v-model="addObject.country"/>
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addClientModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addClient">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>


        <b-modal title="Edit client" v-model="editClientModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="editObject.name"/>
                </b-form-group>

                <b-form-group>
                    <label>Address</label>
                    <b-form-input v-model="editObject.address"/>
                </b-form-group>

                <b-form-group>
                    <label>Country</label>
                    <b-form-input v-model="editObject.country"/>
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editClientModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="saveClient">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BCard, BOverlay, BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BasicTable,
            BCard,
            BOverlay,
            BButton,
            BFormGroup,
            BFormInput,
            BModal
        },
        data() {
            return {
                dataLoaded: false,
                clients: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Address',
                        displayType: 0,
                        field: 'address',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Country',
                        displayType: 0,
                        field: 'country',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addClientModalActive: false,
                addObject: {
                    name: '',
                    address: '',
                    country: ''
                },

                editClientModalActive: false,
                editObject: {}
            }
        },
        methods: {
            openEdit(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.editClientModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/client')
                loadPromise.then(function(response) {
                    thisIns.clients = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addClient() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/client', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Client added')
                    thisIns.addClientModalActive = false
                    thisIns.addObject = {
                        name: '',
                        address: '',
                        country: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            saveClient() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/client/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {
                    thisIns.$printSuccess('Client saved')
                    thisIns.editClientModalActive = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeClient(id) {
                const thisIns = this
                const savePromise = this.$http.delete(`/api/management/v1/client/${  id}`)
                savePromise.then(function() {
                    thisIns.$printSuccess('Client removed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        computed: {
            clientsFormatted() {
                return this.clients.map((client, index) => {
                    return {
                        index,
                        id: client.id,
                        name: client.name,
                        address: client.address,
                        country: client.country
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>